/*
 * Copyright (C) 2020 Airfordable, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { styled, Typography } from '@material-ui/core';
import { graphql } from 'gatsby';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import React from 'react';

import { Col, Container } from 'components/UI';
import { Breakpoints } from 'utils/enums';

const Title = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.h4.fontSize,
  marginBottom: theme.spacing(8),
  marginTop: theme.spacing(16),

  [theme.breakpoints.up(Breakpoints.Md)]: {
    fontSize: theme.typography.h3.fontSize,
    fontWeight: 500,
    marginTop: theme.spacing(34),
  },
}));

const List = styled('ul')(({ theme }) => ({
  [theme.breakpoints.up(Breakpoints.Md)]: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },

  margin: 0,
  padding: 0,
}));

const Card = styled('li')(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  borderRadius: theme.borderRadius[3],
  display: 'flex',
  listStyle: 'none',
  marginBottom: '1em',
  padding: theme.spacing(5),
  [theme.breakpoints.up(Breakpoints.Md)]: {
    width: 'calc(100% / 2 - .5em)',
  },
}));

const Content = styled('div')({
  flex: 1,
  marginRight: '2em',
});

const AvatarWrapper = styled('div')({
  position: 'relative',
});

const Avatar = styled(GatsbyImage)(({ theme }) => ({
  borderRadius: theme.borderRadius[2],
  width: theme.spacing(21),
}));

const Asset = styled(GatsbyImage)(({ theme }) => ({
  borderRadius: '100%',
  left: '-50%',
  position: 'absolute !important' as any,
  top: theme.spacing(2.4),
  width: theme.spacing(17),
}));

const CardTitle = styled(Typography)({
  fontWeight: 500,
});

const CardRole = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const Other = styled(Typography)({
  textAlign: 'right',
});

const Supported: React.FC<SupportedProps> = ({ content }) => {
  return (
    <Container>
      <Col item md />
      <Col item xs={12} md={9} style={{ position: 'relative' }}>
        <Title>{content.title}</Title>

        <List>
          {content.list.map((item) => {
            return (
              <Card key={item.name}>
                <Content>
                  <CardTitle>{item.name}</CardTitle>
                  <CardRole>{item.role}</CardRole>
                  <Typography color="textSecondary">{item.company}</Typography>
                </Content>

                <AvatarWrapper>
                  {item.avatar && (
                    <Avatar
                      alt=""
                      image={item.avatar.childImageSharp.gatsbyImageData}
                    />
                  )}
                  {item.asset && (
                    <Asset
                      alt=""
                      image={item.asset.childImageSharp.gatsbyImageData}
                    />
                  )}
                </AvatarWrapper>
              </Card>
            );
          })}
        </List>

        <Other color="textSecondary">Among others</Other>
      </Col>
      <Col item md />
    </Container>
  );
};

type ImageProps = { childImageSharp: { gatsbyImageData: IGatsbyImageData } };

export interface SupportedProps {
  content: {
    title: string;
    list: {
      name: string;
      role: string;
      company: string;
      avatar?: ImageProps;
      asset?: ImageProps;
    }[];
  };
}

const query = graphql`
  fragment OurStorySupported on OurStoryYaml {
    supported {
      title
      list {
        name
        role
        company
        asset {
          childImageSharp {
            gatsbyImageData
          }
        }
        avatar {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`;

export { query, Supported };
