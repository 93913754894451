/*
 * Copyright (C) 2020 Airfordable, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { Grid, styled, Typography } from '@material-ui/core';
import { graphql } from 'gatsby';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import React from 'react';

import { Col, Container, Link, TitleHighlight } from 'components/UI';
import { Breakpoints } from 'utils/enums';

const Wrapper = styled('div')(({ theme }) => ({
  margin: theme.spacing(16, 0),
  [theme.breakpoints.up(Breakpoints.Md)]: {
    marginBottom: 0,
    marginTop: theme.spacing(50),
  },
}));

const Title = styled(TitleHighlight)(({ theme }) => ({
  '& span': {
    display: 'block',
    width: 'max-content',
  },
  ...theme.typography.h4,
  marginBottom: theme.spacing(20),
  [theme.breakpoints.up(Breakpoints.Md)]: {
    ...theme.typography.h3,
  },
}));

const Icon = styled(GatsbyImage)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  width: theme.spacing(14),
}));

const Card = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(10),
  padding: theme.spacing(0, 2),
}));

const CardTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  marginBottom: theme.spacing(1),
}));

const CardLink = styled(Link)(({ theme }) => ({
  '& *': {
    fontWeight: 500,
  },
  display: 'block',
  marginTop: theme.spacing(4),
  [theme.breakpoints.up(Breakpoints.Md)]: {
    marginTop: theme.spacing(10),
  },
}));

const Position: React.FC<PositionProps> = ({ content }) => {
  return (
    <Wrapper>
      <Container>
        <Col item md />

        <Col item sm={12} md={9}>
          <Grid container>
            <Col item sm={12} md={4}>
              <Title as="h2">{content.title}</Title>
            </Col>

            {content.list.map((item) => {
              return (
                <Col md item key={item.title}>
                  <Card>
                    <Icon
                      alt=""
                      image={item.icon.childImageSharp.gatsbyImageData}
                    />
                    <CardTitle>{item.title}</CardTitle>
                    <Typography>{item.text}</Typography>

                    <CardLink to={item.linkUrl}>{item.linkText}</CardLink>
                  </Card>
                </Col>
              );
            })}
          </Grid>
        </Col>

        <Col item md />
      </Container>
    </Wrapper>
  );
};

export interface PositionProps {
  content: {
    title: string;
    list: {
      icon: { childImageSharp: { gatsbyImageData: IGatsbyImageData } };
      title: string;
      text: string;
      linkText: string;
      linkUrl: string;
    }[];
  };
}

const query = graphql`
  fragment OurStoryPosition on OurStoryYaml {
    position {
      title
      list {
        title
        text
        linkText
        linkUrl
        icon {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`;

export { Position, query };
