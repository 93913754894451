/*
 * Copyright (C) 2020 Airfordable, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { styled } from '@material-ui/core';
import { graphql } from 'gatsby';
import { IGatsbyImageData, IImage } from 'gatsby-plugin-image';
import React from 'react';

import { Overflow } from '../../../partials/Overflow';
import {
  Col,
  Container,
  Illustration,
  TitleHighlight,
  Typography,
} from 'components/UI';
import { Breakpoints } from 'utils/enums';
import { textByLine } from 'utils/textByLine';

const Title = styled(TitleHighlight)(({ theme }) => ({
  '& span': {
    display: 'block',
    width: 'max-content',
  },
  ...theme.typography.h4,
  marginBottom: theme.spacing(8),
  marginTop: theme.spacing(4),
  [theme.breakpoints.up(Breakpoints.Md)]: {
    marginBottom: theme.spacing(20),
    marginTop: 0,
    ...theme.typography.h2,
  },
}));

const LeftCol = styled(Col)(({ theme }) => ({
  '& h4, & p': {
    marginBottom: '1em',
  },
  [theme.breakpoints.up(Breakpoints.Md)]: {
    '& > div': {
      paddingLeft: '20%',
    },
    flex: 1,
  },
}));

const RightCol = styled(Col)(({ theme }) => ({
  '& h4, & p': {
    marginBottom: '1em',
  },
  [theme.breakpoints.up(Breakpoints.Md)]: {
    '& > div': {
      paddingRight: '33%',
    },
    flex: 1,
  },
}));

const Gutter = styled(Col)(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.up(Breakpoints.Md)]: {
    display: 'block',
    flex: 0.2,
  },
}));

const HighlightWrapper = styled('div')(({ theme }) => ({
  '& .image': {
    position: 'absolute !important' as any,
  },
  '& .image:nth-child(1)': {
    '& .asset': {
      left: '-61%',
      top: '1%',
      width: '192%',
    },
    left: '5%',
    top: '2%',
    width: '40%',
    [theme.breakpoints.up(Breakpoints.Md)]: {
      left: '20%',
      maxWidth: theme.spacing(50),
      top: '-18%',
    },
  },
  '& .image:nth-child(2)': {
    '& .asset': {
      left: '-36%',
      top: '-39%',
      width: '160%',
    },
    right: '-10%',
    top: '27%',
    width: '50%',
    [theme.breakpoints.up(Breakpoints.Md)]: {
      bottom: 0,
      left: '9%',
      maxWidth: theme.spacing(78),
      right: 'auto',
      top: 'auto',
      width: '30%',
    },
  },
  '& .image:nth-child(3)': {
    '& .asset': {
      right: '-5%',
      top: '-21%',
      width: '129%',
    },
    bottom: '10%',
    right: '-10%',
    width: '55%',
    [theme.breakpoints.up(Breakpoints.Md)]: {
      bottom: '30%',
      maxWidth: theme.spacing(57),
      right: '1em',
      width: '40%',
    },
  },
  '& .image:nth-child(4)': {
    '& .asset': {
      bottom: '-20%',
      right: '-40%',
      width: '70%',
    },

    bottom: '4%',
    right: '20%',
    width: '60%',
    [theme.breakpoints.up(Breakpoints.Md)]: {
      bottom: '10%',
      maxWidth: theme.spacing(70),
      right: '4%',
      width: '40%',
    },
  },
  [theme.breakpoints.up(Breakpoints.Md)]: {
    padding: theme.spacing(95, 0),
  },
  padding: '140vw 0',
  position: 'relative',
}));

const Highlight = styled(Typography)(({ theme }) => ({
  margin: `auto`,
  textAlign: 'center',
  width: '94%',
  [theme.breakpoints.down(Breakpoints.Sm)]: {
    ...theme.typography.h4,
  },
  [theme.breakpoints.up(Breakpoints.Sm)]: {
    width: theme.spacing(187),
  },
}));

const QuoteTitle = styled('h2')({
  fontSize: '1.4rem',
  fontStyle: 'italic',
  fontWeight: 500,
  marginTop: 0,
});

const Story: React.FC<StoryProps> = ({ content }) => {
  return (
    <Overflow>
      <Container>
        <LeftCol>
          <div>
            <Title as="h2">{content.title}</Title>
          </div>
        </LeftCol>
        <Gutter />
        <RightCol />
      </Container>

      <Container>
        <LeftCol>
          <div>
            {textByLine(content.text).map((paragraph) => (
              <QuoteTitle key={paragraph}>{paragraph}</QuoteTitle>
            ))}
          </div>
        </LeftCol>

        <Gutter />

        <RightCol>
          <div>
            {textByLine(content.body).map((paragraph) => (
              <Typography key={paragraph}>{paragraph}</Typography>
            ))}
          </div>
        </RightCol>
      </Container>

      <HighlightWrapper>
        {content.images.map((image, index) => (
          <Illustration
            alt=""
            className="image"
            key={`image-${index}`}
            image={image.main.childImageSharp.gatsbyImageData}
            asset={image.asset?.childImageSharp.original.src}
            assetAspectRatio={
              image.asset
                ? image.asset?.childImageSharp.original.width /
                  image.asset?.childImageSharp.original.height
                : undefined
            }
          />
        ))}
        <Highlight variant="h2">{content.highlightText}</Highlight>
      </HighlightWrapper>
    </Overflow>
  );
};

export interface StoryProps {
  content: {
    title: string;
    text: string;
    body: string;
    highlightText: string;
    images: {
      main: { childImageSharp: { gatsbyImageData: IGatsbyImageData } };
      asset: { childImageSharp: { original: IImage } };
    }[];
  };
}

const query = graphql`
  fragment OurStoryStory on OurStoryYaml {
    story {
      title
      text
      body
      highlightText
      images {
        main {
          childImageSharp {
            gatsbyImageData
          }
        }
        asset {
          childImageSharp {
            original {
              height
              width
              src
            }
          }
        }
      }
    }
  }
`;

export { query, Story };
