/*
 * Copyright (C) 2020 Airfordable, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { graphql } from 'gatsby';
import React from 'react';

import { Hero, HeroProps } from 'components/contents/OurStory/Hero';
import { Position, PositionProps } from 'components/contents/OurStory/Position';
import { Story, StoryProps } from 'components/contents/OurStory/Story';
import {
  Supported,
  SupportedProps,
} from 'components/contents/OurStory/Supported';
import { WhoWeAre, WhoWeAreProps } from 'components/contents/OurStory/WhoWeAre';
import Layout from 'components/partials/Layout';

// Types

interface Props {
  data: {
    ourStoryYaml: {
      metadata: MetaData;
      hero: HeroProps['content'];
      story: StoryProps['content'];
      whoWeAre: WhoWeAreProps['content'];
      supported: SupportedProps['content'];
      position: PositionProps['content'];
    };
  };
}

const Home: React.FC<Props> = ({ data }) => {
  return (
    <Layout
      title={data.ourStoryYaml.metadata.title}
      description={data.ourStoryYaml.metadata.description}
    >
      <Hero content={data.ourStoryYaml.hero} />
      <Story content={data.ourStoryYaml.story} />
      <WhoWeAre content={data.ourStoryYaml.whoWeAre} />
      <Supported content={data.ourStoryYaml.supported} />
      <Position content={data.ourStoryYaml.position} />
    </Layout>
  );
};

export const query = graphql`
  query ourStoryQuery {
    ourStoryYaml {
      metadata {
        title
        description
      }
      ...OurStoryHero
      ...OurStoryStory
      ...OurStoryWhoWeAre
      ...OurStorySupported
      ...OurStoryPosition
    }
  }
`;

export default Home;
