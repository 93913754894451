/*
 * Copyright (C) 2020 Airfordable, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { styled } from '@material-ui/core';
import { graphql } from 'gatsby';
import { IGatsbyImageData, IImage } from 'gatsby-plugin-image';
import React from 'react';

import { Overflow } from 'components/partials/Overflow';
import {
  Col,
  Container,
  Illustration,
  TitleHighlight,
  Typography,
} from 'components/UI';
import { Breakpoints } from 'utils/enums';
import { textByLine } from 'utils/textByLine';

const Wrapper = styled('div')(({ theme }) => ({
  [theme.breakpoints.up(Breakpoints.Sm)]: {
    marginBottom: theme.spacing(25),
  },
  [theme.breakpoints.up(Breakpoints.Md)]: {
    marginBottom: theme.spacing(75),
  },
}));

const WrapperHeader = styled('div')(({ theme }) => ({
  '& .image:nth-child(1)': {
    '& .asset': {
      left: '-40%',
      top: '4%',
      width: '176%',
    },
    bottom: '30%',
    left: 0,
    position: 'absolute !important' as any,
    width: '50%',
    [theme.breakpoints.up(Breakpoints.Sm)]: {
      bottom: 'auto',
      left: '-20%',
      position: 'absolute !important' as any,
      top: 0,
      width: theme.spacing(65),
    },
    [theme.breakpoints.up(Breakpoints.Md)]: {
      left: theme.spacing(10),
    },
  },
  '& .image:nth-child(2)': {
    '& .asset': {
      left: '2%',
      top: '3%',
      width: '98%',
    },

    bottom: '10%',
    position: 'absolute !important' as any,
    right: 0,
    width: '40%',

    [theme.breakpoints.up(Breakpoints.Sm)]: {
      bottom: 0,
      position: 'absolute !important' as any,
      right: '-20%',
      width: theme.spacing(75),
    },
    [theme.breakpoints.up(Breakpoints.Md)]: {
      right: '-10%',
    },
  },
  [theme.breakpoints.up(Breakpoints.Sm)]: {
    display: 'flex',
    marginBottom: theme.spacing(33),
    minHeight: '60vh',
    paddingBottom: 0,
    width: '100%',
  },
  marginBottom: theme.spacing(8),
  marginTop: theme.spacing(8),
  paddingBottom: '90vw',
  position: 'relative',
}));

const Header = styled('div')({
  margin: 'auto',
  textAlign: 'center',
});

const Title = styled(TitleHighlight)(({ theme }) => ({
  '& span': {
    display: 'block',
    margin: 'auto',
    width: 'max-content',
  },
  ...theme.typography.h4,
  marginBottom: theme.spacing(4),
  [theme.breakpoints.up(Breakpoints.Md)]: {
    marginBottom: theme.spacing(12),
    ...theme.typography.h3,
  },
  [theme.breakpoints.up(Breakpoints.Lg)]: {
    ...theme.typography.h2,
  },
}));

const Text = styled(Typography)(({ theme }) => ({
  padding: '0px 10%',
  [theme.breakpoints.up(Breakpoints.Sm)]: {
    padding: '0px 30%',
  },
}));

const Gutter = styled(Col)(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.up(Breakpoints.Md)]: {
    display: 'block',
    flex: 0.2,
  },
}));

const BodyCol = styled(Col)(({ theme }) => ({
  '& h4, & p': {
    marginBottom: '1em',
  },
  [theme.breakpoints.up(Breakpoints.Md)]: {
    '& > div': {
      paddingRight: '33%',
    },
    flex: 1,
  },
}));

const ImageCol = styled(Col)(({ theme }) => ({
  '& .image:nth-child(1)': {
    '& .asset': {
      right: '-10%',
      top: '-14%',
      width: '120%',
    },

    display: 'none',

    [theme.breakpoints.up(Breakpoints.Md)]: {
      bottom: 'auto',
      display: 'block',
      left: 0,
      position: 'absolute !important' as any,
      top: '-30%',
      width: theme.spacing(69),
    },
  },
  '& .image:nth-child(2)': {
    '& .asset': {
      right: '-3%',
      top: '-15%',
      width: '40%',
    },

    bottom: '10%',
    left: 0,
    position: 'absolute !important' as any,
    width: '50%',
    [theme.breakpoints.up(Breakpoints.Md)]: {
      bottom: 'auto',
      left: 'auto',
      position: 'absolute !important' as any,
      right: '-10%',
      top: '-5%',
      width: theme.spacing(60),
    },
  },
  '& .image:nth-child(3)': {
    bottom: '15%',
    position: 'absolute !important' as any,
    right: '-10%',
    width: '50%',
    [theme.breakpoints.up(Breakpoints.Md)]: {
      bottom: theme.spacing(-50),
      left: 0,
      position: 'absolute !important' as any,
      right: 'auto',
      width: theme.spacing(78),
    },
  },
  [theme.breakpoints.up(Breakpoints.Md)]: {
    flex: 1,
    order: -1,
    paddingBottom: '0 !important' as any,
  },
  paddingBottom: '100vw !important' as any,
  position: 'relative',
  width: '100%',
}));

const QuoteTitle = styled(Typography)(({ theme }) => ({
  fontStyle: 'italic',
  [theme.breakpoints.up(Breakpoints.Md)]: theme.typography.h4,
}));

const Hero: React.FC<HeroProps> = ({ content }) => {
  const headerImages = content.images.filter((_, i) => i < 2);
  const bodyImages = content.images.filter((_, i) => i >= 2);

  return (
    <Overflow>
      <Wrapper>
        <Container>
          <WrapperHeader>
            {headerImages?.map((image, index) => (
              <Illustration
                alt=""
                className="image"
                key={`image-${index}`}
                image={image.main.childImageSharp.gatsbyImageData}
                asset={image.asset?.childImageSharp.original.src}
                assetAspectRatio={
                  image.asset
                    ? image.asset?.childImageSharp.original.width /
                      image.asset?.childImageSharp.original.height
                    : undefined
                }
              />
            ))}
            <Header>
              <Title as="h2">{content.title}</Title>
              <Text>{content.text}</Text>
            </Header>
          </WrapperHeader>
        </Container>

        <Container>
          <Gutter />

          <BodyCol>
            <div>
              <QuoteTitle>{content.content.title}</QuoteTitle>
              {textByLine(content.content.body).map((paragraph) => (
                <Typography key={paragraph}>{paragraph}</Typography>
              ))}
            </div>
          </BodyCol>
          <ImageCol>
            {bodyImages?.map((image, index) => (
              <Illustration
                alt=""
                className="image"
                key={`{image-${index}}`}
                image={image.main.childImageSharp.gatsbyImageData}
                asset={image.asset?.childImageSharp.original.src}
                assetAspectRatio={
                  image.asset
                    ? image.asset?.childImageSharp.original.width /
                      image.asset?.childImageSharp.original.height
                    : undefined
                }
              />
            ))}
          </ImageCol>
        </Container>
      </Wrapper>
    </Overflow>
  );
};

export interface HeroProps {
  content: {
    title: string;
    text: string;
    content: {
      title: string;
      body: string;
    };
    images: {
      main: { childImageSharp: { gatsbyImageData: IGatsbyImageData } };
      asset: { childImageSharp: { original: IImage } };
    }[];
  };
}

const query = graphql`
  fragment OurStoryHero on OurStoryYaml {
    hero {
      title
      text
      content {
        title
        body
      }
      images {
        main {
          childImageSharp {
            gatsbyImageData
          }
        }
        asset {
          childImageSharp {
            original {
              height
              width
              src
            }
          }
        }
      }
    }
  }
`;

export { Hero, query };
