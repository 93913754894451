/*
 * Copyright (C) 2020 Airfordable, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { styled, Typography } from '@material-ui/core';
import { graphql } from 'gatsby';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import React from 'react';

import { Col, Container, TitleHighlight } from 'components/UI';
import { Breakpoints } from 'utils/enums';

const Title = styled(TitleHighlight)(({ theme }) => ({
  '& span': {
    display: 'block',
    margin: 'auto',
    width: 'max-content',
  },
  ...theme.typography.h4,
  margin: 'auto',
  marginBottom: theme.spacing(12),
  marginTop: theme.spacing(14),

  [theme.breakpoints.up(Breakpoints.Md)]: {
    marginBottom: theme.spacing(24),
    marginTop: theme.spacing(30),
    textAlign: 'center',
    ...theme.typography.h3,
  },
}));

const List = styled('ul')(({ theme }) => ({
  margin: 0,
  marginBottom: theme.spacing(16),
  padding: 0,

  [theme.breakpoints.up(Breakpoints.Sm)]: {
    alignItems: 'flex-start',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(26),
  },
}));

const Card = styled('li')(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  borderRadius: theme.borderRadius[3],
  listStyle: 'none',
  marginBottom: theme.spacing(4),
  padding: theme.spacing(10),
  width: '100%',

  [theme.breakpoints.up(Breakpoints.Sm)]: {
    width: `calc(100% / 2 - .5em)`,
  },
  [theme.breakpoints.up(Breakpoints.Lg)]: {
    width: `calc(100% / 4 - 1em)`,
  },
}));

const CardIcon = styled(GatsbyImage)(({ theme }) => ({
  marginBottom: theme.spacing(13),
  width: theme.spacing(15),
}));

const CardTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  marginBottom: theme.spacing(3),
}));

const TeamItem = styled('div')(({ theme }) => ({
  '& .arrow': {
    display: 'none',
  },
  [theme.breakpoints.up(Breakpoints.Md)]: {
    '& .arrow': {
      display: 'block',
      position: 'absolute !important' as any,
    },
    '&:nth-child(1)': {
      '& .arrow': {
        right: '-120%',
        top: '120%',
        width: theme.spacing(50),
      },
      left: '-30%',
      top: '35%',
    },
    '&:nth-child(2)': {
      '& .arrow': {
        bottom: '-135%',
        left: '21%',
        width: theme.spacing(14),
      },
      left: '60%',
      top: '5%',
    },
    '&:nth-child(3)': {
      '& .arrow': {
        bottom: '-44%',
        left: '-80%',
        width: theme.spacing(44),
      },
      right: '-25%',
      top: '50%',
    },

    position: 'absolute',
  },
}));

const TeamTitle = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.h4.fontSize,
  marginBottom: theme.spacing(8),

  [theme.breakpoints.up(Breakpoints.Md)]: {
    fontSize: theme.typography.h3.fontSize,
    fontWeight: 500,
    marginBottom: theme.spacing(29),
  },
}));

const TeamName = styled(Typography)(({ theme }) => ({
  fontFamily: theme.typography.subtitle2.fontFamily,
  fontSize: theme.typography.pxToRem(32),
  lineHeight: 1,
  marginTop: theme.spacing(8),
  [theme.breakpoints.up(Breakpoints.Md)]: {
    textAlign: 'center',
  },
}));

const TeamRole = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up(Breakpoints.Md)]: {
    textAlign: 'center',
  },
}));

const WhoWeAre: React.FC<WhoWeAreProps> = ({ content }) => {
  return (
    <>
      <Container>
        <Title as="h2">{content.title}</Title>

        <List>
          {content.list.map((item) => {
            return (
              <Card key={item.title}>
                <CardIcon
                  alt=""
                  image={item.icon.childImageSharp.gatsbyImageData}
                />
                <CardTitle>{item.title}</CardTitle>
                <Typography variant="body2" color="textSecondary">
                  {item.text}
                </Typography>
              </Card>
            );
          })}
        </List>
      </Container>

      <Container>
        <Col item md />
        <Col item xs={12} md={7} style={{ position: 'relative' }}>
          <TeamTitle>{content.leadership.title}</TeamTitle>
          <GatsbyImage
            alt=""
            image={content.leadership.image.childImageSharp.gatsbyImageData}
          />

          <div>
            {content.leadership.team.map((item) => {
              return (
                <TeamItem key={item.name}>
                  <GatsbyImage
                    alt=""
                    className="arrow"
                    image={item.arrow.childImageSharp.gatsbyImageData}
                  />
                  <TeamName>{item.name}</TeamName>
                  <TeamRole>{item.role}</TeamRole>
                </TeamItem>
              );
            })}
          </div>
        </Col>
        <Col item md />
      </Container>
    </>
  );
};

type ImageProps = { childImageSharp: { gatsbyImageData: IGatsbyImageData } };

export interface WhoWeAreProps {
  content: {
    title: string;
    list: { title: string; text: string; icon: ImageProps }[];
    leadership: {
      title: string;
      image: ImageProps;
      team: { name: string; role: string; arrow: ImageProps }[];
    };
  };
}

const query = graphql`
  fragment OurStoryWhoWeAre on OurStoryYaml {
    whoWeAre {
      title
      list {
        title
        text
        icon {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      leadership {
        title
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
        team {
          name
          role
          arrow {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`;

export { query, WhoWeAre };
